import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Injectable, OnDestroy, PLATFORM_ID, Renderer2, RendererFactory2, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter, map } from 'rxjs';
import { CookieConsent, GoogleConsent, CookieConsentNL, NotificationInterface } from '../../../core';
// WINDOW from '@inshared/shared/util' causes circular dependency:
// import { WINDOW } from '@inshared/shared/util';
// That's why we use the deprecated WINDOW object below:
import { WINDOW } from '../../../core/configuration/window.provider';
import { CONSENT_MODE_KEY } from '../../../core/constants';
import { DatalayerConfigInterface } from './datalayer-config.interface';
import { StorageService } from './datalayer-cookie-storage';
import { DatalayerDataInterface } from './datalayer-data.interface';
import { DatalayerLocalStorage } from './datalayer-local-storage.service';
import { PolicyProductInterface } from './policy-product.interface';
import { ProductDatalayerInterface } from './product-datalayer.interface';

declare let dataLayer: any;
declare let cl: any;

/**
 * @deprecated
 * import: DatalayerService from outshared-lib.
 * */
@Injectable({
    providedIn: 'root',
})
export class DatalayerDeprecatedService implements OnDestroy {
    private document: Document = inject(DOCUMENT);
    private platformId: Object = inject(PLATFORM_ID);
    private storage = inject(StorageService);
    private window: Window | Partial<Window> = inject(WINDOW);
    private localStorage: Storage = inject(DatalayerLocalStorage);
    private renderer: Renderer2;
    private subscription: Subscription = new Subscription();
    private gtmId: string;
    private defaultConsent: GoogleConsent = {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
    };

    public cynolyticsCode: string | null = null;

    constructor(
        private router: Router,
        private rendererFactory: RendererFactory2,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public init(props?: DatalayerConfigInterface): void {
        const cookieSetting = this.storage.getItem('ls.cookie.setting');

        if (isPlatformServer(this.platformId)) {
            return;
        }

        if (props?.gtmId) {
            this.gtmId = props.gtmId;
        } else {
            console.error('Datalayer gtmId not set');
            return;
        }

        this.addTagManagerScript();

        if (Boolean(cookieSetting)) {
            this.trackPageview();
        }
    }

    public getCookieId(): string | null {
        this.cynolyticsCode = this.storage.getItem('__cynolytics');
        return this.cynolyticsCode;
    }

    public trackPartner(allProducts: PolicyProductInterface[]): void {
        const lsKey = 'ls.sales:bron';
        const source = this.localStorage.getItem(lsKey);

        if (JSON.parse(source) !== 'partner') {
            return;
        }

        const products = this.filterAllProducts(allProducts);
        const data: DatalayerDataInterface = {
            event: 'purchase',
            ecommerce: {
                currencyCode: 'EUR',
                purchase: {
                    actionField: {
                        id: source,
                        affiliation: 'partner',
                        revenue: products.length,
                        coupon: null,
                    },
                    products,
                },
            },
            ingelogd: 'ja',
            cookie_id: source,
            klant: 'ja',
        };

        this.localStorage.removeItem(lsKey);

        this.sendToDataLayer(data);
        this.sendToCynoLytics(data);
    }

    public send(data: DatalayerDataInterface): void {
        this.sendToCynoLytics(data);
        this.sendToDataLayer(data);
    }

    public sendNotificationsToDatalayer(data: NotificationInterface[]): void {
        if (typeof dataLayer === 'undefined') {
            return;
        }
        data.forEach((notification: NotificationInterface): void => {
            if (notification.notification_type === 'MEDE') {
                return;
            }
            dataLayer.push({
                event: 'melding',
                eventCategory: 'foutmelding-backend',
                eventAction: notification.message_content,
                eventLabel: notification.item,
                eventValue: notification.message_code,
                cookieId: this.getCookieId() || null,
                productId: null,
            });
        });
    }

    private mapConsent(consent: CookieConsent | CookieConsentNL): GoogleConsent {
        switch (consent) {
            case CookieConsent.All:
            case CookieConsentNL.All:
                return {
                    ...this.defaultConsent,
                    ad_storage: 'granted',
                    ad_user_data: 'granted',
                    ad_personalization: 'granted',
                    analytics_storage: 'granted',
                };
            case CookieConsentNL.Extensive:
            case CookieConsentNL.Basic:
            case CookieConsent.Basic:
                return {
                    ...this.defaultConsent,
                    analytics_storage: 'granted',
                };
            default:
                return this.defaultConsent;
        }
    }

    private updateDatalayerConsent(action: 'default' | 'update', consent: GoogleConsent) {
        this.window.gtag('consent', action, consent);
        this.localStorage.setItem(CONSENT_MODE_KEY, JSON.stringify(consent));
    }

    private setConsent() {
        const cookieSetting = this.storage.getItem('ls.cookie.setting');

        this.updateDatalayerConsent('default', this.defaultConsent);

        if (cookieSetting) {
            this.updateDatalayerConsent('update', this.mapConsent(cookieSetting as CookieConsent));
            return;
        }
    }

    private addTagManagerScript(): void {
        this.addGtagScript();
        this.setConsent();

        const textIrame = this.renderer.createText(`<iframe src="//www.googletagmanager.com/ns.html?id=${this.gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`);
        this.document.getElementsByTagName('noscript')[0].appendChild(textIrame);

        this.googleTagManager(window, document, 'script', 'dataLayer', this.gtmId);
    }

    private googleTagManager(w, d, s, l, i): void {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    }

    private addGtagScript() {
        const gtagScript = this.renderer.createElement('script');
        gtagScript.type = 'text/javascript';
        gtagScript.text = `
            window.dataLayer = window.dataLayer || [];

            function gtag() {
              dataLayer.push(arguments);
            }
         `;
        this.renderer.appendChild(this.document.body, gtagScript);
    }

    private trackPageview(): void {
        const router = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects),
                map((path: string) => ({ event: 'pageview', page: { path } }))
            )
            .subscribe((data) => this.sendToDataLayer(data));

        this.subscription.add(router);
    }

    private filterAllProducts(allProducts: PolicyProductInterface[]): ProductDatalayerInterface[] {
        const products: ProductDatalayerInterface[] = [];

        allProducts.forEach((product) => {
            product.permitted_coverage.forEach((coverage) => {
                products.push({
                    name: coverage.coverage_description,
                    id: coverage.coverage_id,
                    price: 1,
                    category: product.product_id,
                    quantity: 1,
                    dimension6: null, // postcode
                    dimension7: product.product_id || null,
                    dimension8: 'verzekeringsmap', // stap
                    dimension9: 'gegenereerd', // nieuw/bestaand
                });
            });
        });

        return products;
    }

    private sendToDataLayer(data: DatalayerDataInterface): void {
        if (typeof dataLayer === 'undefined') {
            return;
        }

        dataLayer.push(data);
    }

    private sendToCynoLytics(data: DatalayerDataInterface): void {
        if (typeof cl === 'undefined') {
            return;
        }

        const payload = {
            event: 'inshared-3.0',
            event_naam: data.event,
            event_categorie: data.eventCategory || null,
            event_waarde: data.eventValue || 1,
            product_id: data.product_id || null,
            ui_element_type: undefined,
            ui_actie: data.eventAction || null,
            ui_element_id: data.eventLabel || null,
        };

        cl('send', 'event', payload);
    }
}
