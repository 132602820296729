import { NotificationInterface } from '../../../core';
import { AddressData } from '../../interfaces';
import * as addressActions from './address.actions';

export interface State {
    data: AddressData;
    errors: NotificationInterface[];
}

const initialState: State = {
    data: null,
    errors: [],
};

export function reducer(state: State = initialState, action: addressActions.AddressActionUnion): State {
    switch (action.type) {
    case addressActions.GET_ADDRESS_DATA: {
        return {
            ...state,
            data: null,
        };
    }

    case addressActions.GET_ADDRESS_DATA_SUCCESS: {
        const addressData = action.payload.result;
        const address = action.payload.address.address;

        return {
            ...state,
            data: {
                postal_code: address.postal_code,
                civic_number: address.civic_number,
                civic_number_suffix: address.civic_number_suffix,
                street_name: addressData.street_name,
                city: addressData.city,
            },
            errors: [],
        };
    }

    case addressActions.GET_ADDRESS_DATA_ERROR: {
        return {
            ...state,
            data: null,
            errors: action.payload.errors,
        };
    }

    default: {
        return state;
    }
    }
}

export const getAddress = (state: State): State['data'] => state.data;
export const getErrors = (state: State): State['errors'] => state.errors;
