import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import { ContentElementRequestInterface, ContentInterface } from '../interfaces';
import { mapContentElementsResponse } from '../mappers';

const RESOURCES = {
    REQUEST_CONTENT_ELEMENTS: 'en-gb/form-general/request/element',
    REQUEST_CONTENT_ELEMENTS_V2: 'en-gb/v2/form-general/request/element',
};

@Injectable({
    providedIn: 'root',
})
export class ContentApiService {
    // Set the version to use for the content API, this makes sure all the content is loaded from the V2 version
    // You only need to set this once, it will be used for all subsequent requests in your application.
    // I recommend setting this in the highest component of your application.
    public useVersion2 = false;

    constructor(private apiService: ApiService) {}

    public requestContentElements$(element: ContentElementRequestInterface): Observable<ContentInterface[]> {
        return this.apiService
            .post$(this.useVersion2 ? RESOURCES.REQUEST_CONTENT_ELEMENTS_V2 : RESOURCES.REQUEST_CONTENT_ELEMENTS, {
                elements: [element],
            })
            .pipe(map(mapContentElementsResponse));
    }
}
